import React from "react";
import ButtonLink from "./ButtonLink";
import { ListItem } from "@material-ui/core";

interface ListItemButtonLinkProps {
  href: string;
  as?: string;
}
const ListItemButtonLink: React.FC<ListItemButtonLinkProps> = ({
  href,
  as,
  children,
}) => {
  return (
    <ListItem button={true} component={ButtonLink} href={href} hrefAs={as}>
      {children}
    </ListItem>
  );
};

export default ListItemButtonLink;
