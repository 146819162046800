import React from "react";
import Link from "next/link";
import MyAppBar from "../src/components/MyAppBar";
import ROUTES from "../routes";
import {
  Container,
  Box,
  Typography,
  Paper,
  ListItemText,
  List,
} from "@material-ui/core";
import AppStoreBadges from "../src/components/AppStoreBadges.component";
import SignIn from "../src/components/SignIn.component";
import { useSelector } from "react-redux";
import {
  selectIsAuthenticated,
  selectIsLoggingIn,
} from "../src/redux/auth/auth.selectors";
import ListItemButtonLink from "../src/components/ListItemButton";
import { useTranslation } from "../i18n";
import { I18N_NAMESPACES } from "../src/i18n_namespaces";
import { Languages } from "../src/Languages";
import MyCircularProgress from "../src/components/MyCircularProgress.component";
import PageWrapper from "../src/components/PageWrapper";

interface IndexProps {}
const Index: React.FC<IndexProps> = (props) => {
  const { t: tCommon, i18n } = useTranslation(I18N_NAMESPACES.COMMON);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoggingIn = useSelector(selectIsLoggingIn);

  if (isLoggingIn) {
    return <MyCircularProgress />;
  }

  return (
    <PageWrapper>
      <MyAppBar />
      <Container maxWidth="md" style={{ flex: 1 }}>
        <Box marginY={4}>
          <Paper elevation={2} style={{ marginTop: "1rem" }}>
            <Box>
              {isAuthenticated && (
                <>
                  <List>
                    <ListItemButtonLink href={`${ROUTES.PROGRAMS_LIST}`}>
                      <ListItemText
                        primary={tCommon("Find a program")}
                        secondary={tCommon(`All available programs`)}
                      />
                    </ListItemButtonLink>
                    <ListItemButtonLink href={`${ROUTES.MY_PROGRAMS}`}>
                      <ListItemText
                        primary={tCommon(`My programs`)}
                        secondary={tCommon(`All the programs you have`)}
                      />
                    </ListItemButtonLink>
                  </List>
                </>
              )}
              <SignIn />
            </Box>
          </Paper>
          <AppStoreBadges languageId={i18n.language as Languages.Ids} />
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default Index;
